/* You can add global styles to this file, and also import other style files */
@import url("font/style.css");

a.disabled {
    cursor: not-allowed;
}

.content-list-container {
    overflow-y: auto;
    max-height: 70vh;
}

i {
    margin:3px;
}

.scania-sans-bold {
  font-family: 'Scania Sans Bold';
}

.toast-message div.card {
    background-color: inherit;
}

