@import "@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?2legc3');
  src:  url('icomoon.eot?2legc3#iefix') format('embedded-opentype'),
    url('icomoon.ttf?2legc3') format('truetype'),
    url('icomoon.woff?2legc3') format('woff'),
    url('icomoon.svg?2legc3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-dcode-"], [class*=" icon-dcode-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dcode-subfunction::before {
  content: "\e900";
  color: goldenrod;
}
.icon-dcode-protocol-service::before {
  content: "\e901";
  color: cornflowerblue;
}
.icon-dcode-identifier-type::before {
  content: "\e902";
}
.icon-dcode-identifier::before {
  content: "\e903";
}
.icon-dcode-diagnostic-service::before {
  content: "\e904";
  color: palevioletred;
}